<template>
  <div>
    <v-main>
      <v-container>
        <slot/>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "LoginLayout"
}
</script>

<style scoped>

</style>